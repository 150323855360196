@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?9wrdng");
  src: url("fonts/icomoon.eot?9wrdng#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?9wrdng") format("woff2"), url("fonts/icomoon.ttf?9wrdng") format("truetype"), url("fonts/icomoon.woff?9wrdng") format("woff"), url("fonts/icomoon.svg?9wrdng#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-threads-logo:before {
  content: "\e918"; }

.icon-X_logo_2023_original:before {
  content: "\e917"; }

.icon-citadela-outline:before {
  content: "\e90d";
  color: #009c95; }

.icon-dog-outline:before {
  content: "\e90e";
  color: #009c95; }

.icon-lake-outline:before {
  content: "\e90f";
  color: #009c95; }

.icon-park-outline:before {
  content: "\e910";
  color: #009c95; }

.icon-skate-outline:before {
  content: "\e911";
  color: #009c95; }

.icon-wifi-outline:before {
  content: "\e912";
  color: #009c95; }

.icon-scroll:before {
  content: "\e900";
  color: #00918e; }

.icon-ilustrace-form-left:before {
  content: "\e901";
  color: #fff; }

.icon-ilustrace-form-right:before {
  content: "\e902";
  color: #fff; }

.icon-arrow-right:before {
  content: "\e903";
  color: #00918e; }

.icon-arrow-down:before {
  content: "\e904";
  color: #00918e; }

.icon-plus:before {
  content: "\e905";
  color: #fff; }

.icon-arrow-left:before {
  content: "\e906";
  color: #00918e; }

.icon-facebook-f-brands:before {
  content: "\e907";
  color: #fff; }

.icon-instagram-brands:before {
  content: "\e908";
  color: #fff; }

.icon-linkedin-in-brands:before {
  content: "\e909";
  color: #fff; }

.icon-logo-footer:before {
  content: "\e90a";
  color: #fff; }

.icon-chevron-right:before {
  content: "\e915"; }

.icon-chevron-left:before {
  content: "\e916"; }

.icon-pause:before {
  content: "\e913"; }

.icon-player:before {
  content: "\e913"; }

.icon-play:before {
  content: "\e914"; }

.icon-player1:before {
  content: "\e914"; }

.icon-twitter:before {
  content: "\e90b"; }

.icon-brand:before {
  content: "\e90b"; }

.icon-tweet:before {
  content: "\e90b"; }

.icon-social:before {
  content: "\e90b"; }

.icon-youtube:before {
  content: "\e90c"; }

.icon-brand1:before {
  content: "\e90c"; }

.icon-social1:before {
  content: "\e90c"; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 133rem; }
  .pux-container:after {
    display: block;
    content: '';
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 5rem;
      padding-right: 5rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -5rem;
      margin-right: -5rem; } }

.pux-container [class^='col'] {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container [class^='col'] {
      padding-left: 5rem;
      padding-right: 5rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 5rem;
      padding-right: 5rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

.small-container {
  max-width: 900px;
  margin: 0 auto; }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #f3f3f3;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 999; }
  @media (min-width: 545px) {
    header {
      background: #fff; } }
  header.down {
    top: -14rem; }
    @media (min-width: 545px) {
      header.down {
        top: -7rem; } }
  header.up {
    top: 0;
    -webkit-box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3);
    -moz-box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3);
    box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3); }
  @media (min-width: 320px) and (max-width: 544px) {
    header .pux-container {
      padding-left: 0;
      padding-right: 0; } }
  header .header {
    height: 7rem;
    background: #676D6F;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    header .header-logo {
      margin: 0 5rem 0 5rem;
      background: #fff;
      display: flex;
      align-items: center; }
      @media (min-width: 545px) {
        header .header-logo {
          margin: 0 5rem 0 0; } }
      header .header-logo a {
        display: inline-block; }
        header .header-logo a span {
          display: none; }
        header .header-logo a img {
          height: 7rem !important; }
    header .header-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      background: #f3f3f3;
      width: 100%;
      height: 7rem;
      justify-content: center;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem);
      -webkit-box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3);
      -moz-box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3);
      box-shadow: 0 8px 8px rgba(119, 124, 127, 0.3); }
      @media (min-width: 545px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto;
          height: auto;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 545px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; } }
        header .header-menu ul li {
          display: block;
          position: relative; }
          @media (min-width: 545px) {
            header .header-menu ul li {
              margin: 0 1rem;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          header .header-menu ul li a {
            display: block;
            padding: 1.5rem 1.5rem;
            color: #000; }
            header .header-menu ul li a:focus, header .header-menu ul li a:hover {
              color: #D81E04;
              text-decoration: none; }
          header .header-menu ul li.Highlighted a {
            color: #D81E04; }
          header .header-menu ul li ul {
            background: #AEAFAB;
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 545px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 20rem;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 100%; } }
          header .header-menu ul li.hovered > a, header .header-menu ul li:hover > a {
            color: #D81E04; }
          header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: #D81E04;
        color: #fff;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 545px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: '';
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    header .header-hamburger {
      padding: 0 15px 0 0;
      display: flex;
      align-items: center; }
      @media (min-width: 545px) {
        header .header-hamburger {
          display: none; } }
      header .header-hamburger .open-menu {
        width: 6rem;
        height: 6rem;
        position: relative;
        display: block; }
        header .header-hamburger .open-menu span {
          width: 5rem;
          position: absolute;
          left: 0.5rem;
          height: 2px;
          background: #D81E04;
          margin-top: -1px;
          top: 50%;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
            content: '';
            width: 5rem;
            left: 0;
            height: 2px;
            background: #D81E04;
            position: absolute;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before {
            top: -1rem; }
          header .header-hamburger .open-menu span:after {
            bottom: -1rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: flex; }
  header .dark-box {
    display: none; }
    @media (min-width: 545px) {
      header .dark-box {
        width: 8rem;
        background: #777c7f;
        display: block; } }
  header .second-box,
  header .main-box {
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 1.5rem;
    min-width: 165px;
    flex-grow: 1; }
    @media (min-width: 320px) {
      header .second-box,
      header .main-box {
        font-size: 3rem; } }
    @media (min-width: 480px) {
      header .second-box,
      header .main-box {
        font-size: 2.72727rem; } }
    @media (min-width: 545px) {
      header .second-box,
      header .main-box {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      header .second-box,
      header .main-box {
        font-size: 2rem; } }
    @media (min-width: 992px) {
      header .second-box,
      header .main-box {
        font-size: 1.875rem; } }
    @media (min-width: 1200px) {
      header .second-box,
      header .main-box {
        font-size: 1.66667rem; } }
    @media (min-width: 1400px) {
      header .second-box,
      header .main-box {
        font-size: 1.6rem; } }
    @media (min-width: 545px) {
      header .second-box,
      header .main-box {
        flex-grow: 0; } }
  header .second-box {
    background: #D81E04;
    border-right: 0.5px rgba(255, 255, 255, 0.5) solid; }
    header .second-box:focus, header .second-box:hover {
      color: #fff;
      text-decoration: none;
      background: #bf1b04; }
  header .main-box {
    background: #D81E04; }
    header .main-box:focus, header .main-box:hover {
      color: #fff;
      text-decoration: none;
      background: #bf1b04; }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  padding: 3rem 0 3rem 0;
  color: #fff;
  background: #777c7f; }
  footer .footer-logo img {
    max-height: 65px !important; }
  footer .footer-gdpr {
    margin: 0 0 0 4rem; }
  footer .footer {
    text-align: center; }
    @media (min-width: 545px) {
      footer .footer {
        text-align: left; } }
  footer .footer-social {
    max-width: 150px;
    display: inline-block;
    min-width: 150px; }
    footer .footer-social ul {
      margin: 0 -1rem;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      footer .footer-social ul li {
        margin: 0;
        padding: 0 1rem; }
        footer .footer-social ul li a {
          font-weight: 400;
          text-decoration: none; }
          footer .footer-social ul li a.facebook:before {
            content: '\e907';
            font-size: 30px; }
          footer .footer-social ul li a.instagram:before {
            content: '\e908';
            font-size: 27px; }
          footer .footer-social ul li a.linkedin:before {
            content: '\e909';
            font-size: 27px; }
          footer .footer-social ul li a.youtube:before {
            content: '\e90c';
            font-size: 35px; }
          footer .footer-social ul li a.twitter:before {
            content: '\e917';
            font-size: 27px; }
          footer .footer-social ul li a.threads:before {
            content: '\e918';
            font-size: 27px; }
          footer .footer-social ul li a:focus, footer .footer-social ul li a:hover {
            color: #AEAFAB; }
  footer h4 {
    color: #fff;
    margin: 3rem 0 3rem 0; }
  footer a {
    color: #fff;
    text-decoration: underline; }
    footer a:focus, footer a:hover {
      color: #fff;
      text-decoration: none; }
  footer .footer {
    border-bottom: 2px solid #fff;
    padding: 0 0 4rem 0; }
  footer .copyright {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 2rem 0 0 0; }
    footer .copyright-item {
      padding: 1rem; }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.cms-bootstrap [class^='icon-']:before,
.cms-bootstrap [class*=' icon-']:before {
  font-family: 'Core-icons' !important; }

.juicer-feed *:before {
  font-family: 'fontawesome-webfont' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.juicer-feed *:before,
.juicer-feed *:after {
  font-family: 'fontawesome-5-juicer', Helvetica, Arial, sans-serif !important; }

.juicer-feed li {
  margin: 0;
  padding: 0; }
  .juicer-feed li:before {
    display: none; }

.juicer-feed button.slick-next.slick-arrow:before,
.juicer-feed button.slick-prev.slick-arrow:before {
  color: #fff; }

.juicer-feed button.slick-next.slick-arrow:hover:before,
.juicer-feed button.slick-prev.slick-arrow:hover:before {
  color: #AEAFAB; }

.j-post-overlay.juicer-feed .j-overlay-text .juicer-button {
  background-color: #fff;
  border-color: #D81E04; }
  .j-post-overlay.juicer-feed .j-overlay-text .juicer-button:hover {
    background-color: #D81E04;
    color: #fff !important; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "Poppins", sans-serif;
  height: 100%;
  color: #000; }

@media (min-width: 320px) {
  body {
    font-size: 3.2rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.90909rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.66667rem; } }

@media (min-width: 768px) {
  body {
    font-size: 2.13333rem; } }

@media (min-width: 992px) {
  body {
    font-size: 2rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.8rem; } }

img:not(.j-content-image) {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  color: #D81E04;
  margin: 4rem 0 3rem 0;
  font-weight: 700;
  font-family: "Poppins", sans-serif; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 6rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 5.45455rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      font-size: 5rem; } }

h2,
.h2 {
  color: #D81E04;
  margin: 4rem 0;
  font-weight: 700;
  font-family: "Poppins", sans-serif; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 4.54545rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 4.16667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3.5rem; } }

h3,
.h3 {
  color: #D81E04;
  font-weight: 700;
  font-family: "Poppins", sans-serif; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 2.66667rem; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 2.4rem; } }

h4,
.h4 {
  color: #D81E04;
  font-weight: bold; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 2rem; } }
  @media (min-width: 1400px) {
    h4,
    .h4 {
      font-size: 1.8rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 3rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.72727rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 2rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.875rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.66667rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.6rem; } }

a {
  color: #D81E04;
  cursor: pointer; }
  a:focus, a:hover {
    color: #f12104;
    outline: none;
    text-decoration: underline; }

.link {
  text-decoration: none; }
  .link:before {
    font-size: 12px; }
  .link:focus, .link:hover {
    text-decoration: none; }

.link-back {
  display: inline-flex;
  align-items: center; }
  .link-back:before {
    content: '\e906';
    margin: 0 1.5rem 0 0; }

.link-arrow {
  display: inline-flex;
  align-items: center; }
  .link-arrow:before {
    content: '\e903';
    margin: 0 1.5rem 0 0; }

.scroll-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #fff;
  position: absolute;
  bottom: -5rem;
  left: 50%;
  z-index: 44;
  -webkit-transform: rotate(45deg) translatex(-50%);
  -moz-transform: rotate(45deg) translatex(-50%);
  -ms-transform: rotate(45deg) translatex(-50%);
  -o-transform: rotate(45deg) translatex(-50%);
  transform: rotate(45deg) translatex(-50%); }
  .scroll-btn:before {
    transition: 0.3s ease color;
    content: '\e900';
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg); }
    @media (min-width: 320px) {
      .scroll-btn:before {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .scroll-btn:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .scroll-btn:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .scroll-btn:before {
        font-size: 3rem; } }
  .scroll-btn:focus, .scroll-btn:hover {
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 2.25rem 5rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: bold; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 1.8rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: #d82015;
  color: #fff; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #c11d13;
    color: #fff; }

.btn-default {
  background: #D81E04;
  color: #fff; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #ec2104; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem;
  padding: 7rem 0 0 0; }
  main a {
    font-weight: bold; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2.5rem; }
      main ol li:before {
        content: counter(my-ol);
        counter-increment: my-ol;
        color: #D81E04;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "Poppins", sans-serif !important; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 4rem;
      position: relative; }
      main ul li:before {
        content: '';
        width: 2rem;
        height: 1px;
        background: #D81E04;
        position: absolute;
        top: 11px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #f3f3f3; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #f3f3f3; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Poppins", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.label-form,
label {
  display: block;
  margin: 3rem 0 1rem 0; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 0 1.5rem;
  border: 2px solid transparent;
  background: #fff;
  color: #000;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type='text']::-webkit-input-placeholder,
  input[type='number']::-webkit-input-placeholder,
  input[type='email']::-webkit-input-placeholder,
  input[type='password']::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type='text']:-moz-placeholder,
  input[type='number']:-moz-placeholder,
  input[type='email']:-moz-placeholder,
  input[type='password']:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type='text']::-moz-placeholder,
  input[type='number']::-moz-placeholder,
  input[type='email']::-moz-placeholder,
  input[type='password']::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type='text']:-ms-input-placeholder,
  input[type='number']:-ms-input-placeholder,
  input[type='email']:-ms-input-placeholder,
  input[type='password']:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type='text']:focus,
  input[type='number']:focus,
  input[type='email']:focus,
  input[type='password']:focus {
    outline: 0;
    border: 1px solid #AEAFAB; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    border: 1px solid #AEAFAB; }

input[type='submit'] {
  cursor: pointer; }

.required label:before {
  content: '*';
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: '*';
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #fff;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #D81E04;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #AEAFAB;
  color: #000; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type='text'],
.form-validation-error input[type='password'],
.form-validation-error input[type='email'],
.form-validation-error input[type='number'],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type='text'],
.Error:not(.form-validation-valid) input[type='password'],
.Error:not(.form-validation-valid) input[type='email'],
.Error:not(.form-validation-valid) input[type='number'],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #fff;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio input[type='radio'] {
  display: none; }
  .radio input[type='radio'] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type='radio'] + label:before {
      content: '';
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type='radio'] + label:after {
      content: '';
      display: block;
      left: 5px;
      top: 10px;
      width: 6px;
      height: 6px;
      background: #d82015;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type='radio']:checked + label:before {
    border: 1px solid #AEAFAB; }
  .radio input[type='radio']:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type='checkbox'] {
  display: none; }
  .checkbox input[type='checkbox'] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type='checkbox'] + label:before {
      content: '';
      width: 16px;
      height: 16px;
      background: #fff;
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type='checkbox'] + label:after {
      content: '';
      display: block;
      left: 5px;
      top: 10px;
      width: 6px;
      height: 6px;
      background: #d82015;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type='checkbox']:checked + label:before {
    border: 1px solid #AEAFAB; }
  .checkbox input[type='checkbox']:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem;
  text-align: left; }

.row-form [class^='col'] {
  padding-left: 1rem;
  padding-right: 1rem; }

.form-gdpr {
  display: inline-block; }
  .form-gdpr a {
    color: #fff !important; }

.dark-box {
  background-color: #676D6F;
  color: #fff; }
  .dark-box h2,
  .dark-box h3 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 2.5rem; }
  .dark-box .ErrorLabel {
    display: none; }
  .dark-box .row-form {
    padding: 1rem 0 0 0; }
  @media (min-width: 1200px) {
    .dark-box.contact-bgc {
      background-image: url("../img/ilustrace-group-3.svg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      min-height: 40vw;
      padding-left: 20vw;
      padding-right: 20vw; } }

.tree-bgc {
  position: relative; }
  @media (min-width: 1200px) {
    .tree-bgc:before {
      content: '';
      position: absolute;
      bottom: -5rem;
      left: 0;
      width: 100%;
      height: 1186px;
      background-image: url("../img/ilustrace-group-2.svg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      z-index: 22; } }
  .tree-bgc .pux-container {
    position: relative;
    z-index: 44; }

.first-bgc {
  position: relative; }
  @media (min-width: 1200px) {
    .first-bgc:before {
      content: '';
      position: absolute;
      top: 8rem;
      left: 0;
      width: 100%;
      height: 686px;
      background-image: url("../img/ilustrace-group-1.svg");
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      z-index: 22; } }
  .first-bgc .pux-container {
    position: relative;
    z-index: 44; }

.light-box {
  background: #AEAFAB;
  color: #676D6F;
  position: relative; }
  .light-box h2,
  .light-box h3 {
    margin-top: 0;
    color: #676D6F; }
  .light-box .scroll-btn {
    background: #AEAFAB; }

.white-box {
  position: relative;
  color: #676D6F; }
  .white-box h2,
  .white-box h3 {
    margin-top: 0;
    color: #676D6F; }

.form-container {
  max-width: 650px;
  margin: 0 auto; }

.title-box {
  display: flex;
  margin: 0 -1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 4.5rem 0; }
  .title-box-item {
    padding: 0 1rem 2rem 1rem; }
  .title-box h2 {
    margin: 0; }

.news-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding: 1.5rem 0 0 0; }
  .news-container .news-box {
    width: 100%;
    padding: 0 1rem 2rem 1rem; }
    @media (min-width: 768px) {
      .news-container .news-box {
        width: 33.33%; } }

.news-detail {
  color: #676D6F; }
  .news-detail h1 {
    color: #676D6F; }
  .news-detail .perex {
    padding: 0 0 3rem 0; }
  .news-detail-image {
    padding: 0 0 3rem 0; }
  .news-detail-content {
    padding: 0 0 4rem 0; }

.news-item {
  display: block;
  color: #676D6F;
  font-weight: 400; }
  .news-item-image {
    width: 100%;
    height: 200px;
    background-size: cover !important;
    background-position: top center !important; }
    @media (min-width: 768px) {
      .news-item-image {
        height: 23rem; } }
  .news-item-content-name {
    margin: 0;
    padding: 2rem 0 2rem 0; }
  .news-item-content-link {
    margin: 2rem 0 0 0;
    color: #D81E04;
    font-weight: bold; }
  .news-item:focus, .news-item:hover {
    text-decoration: none;
    color: #676D6F; }
    .news-item:focus .news-item-content-link, .news-item:hover .news-item-content-link {
      color: #ec2104; }

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1rem;
  padding: 1rem 0 0 0; }
  .project-container .project-box {
    width: 100%;
    padding: 0 1rem 2rem 1rem; }
    @media (min-width: 480px) {
      .project-container .project-box {
        width: 50%; } }
    @media (min-width: 545px) {
      .project-container .project-box {
        width: 33.33%; } }
    @media (min-width: 1200px) {
      .project-container .project-box {
        width: 20%; } }

.project-item {
  display: block; }
  .project-item-image {
    position: relative;
    padding-top: 108%;
    width: 100%;
    border: 2px solid #fff;
    display: flex;
    background-size: cover !important;
    background-position: top center !important;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    box-shadow: 0 0 0 0 #D81E04; }
    .project-item-image-inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(216, 32, 21, 0.7);
      width: 100%;
      opacity: 0;
      transition: border-color 200ms ease-in-out; }
      .project-item-image-inner span {
        color: #fff; }
  .project-item-name {
    font-weight: 600;
    color: #676D6F;
    padding: 2rem 0 0 0; }
    @media (min-width: 320px) {
      .project-item-name {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .project-item-name {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .project-item-name {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .project-item-name {
        font-size: 2.4rem; } }
    @media (min-width: 992px) {
      .project-item-name {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .project-item-name {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .project-item-name {
        line-height: 4.2rem; } }
    @media (min-width: 480px) {
      .project-item-name {
        line-height: 3.81818rem; } }
    @media (min-width: 545px) {
      .project-item-name {
        line-height: 3.5rem; } }
    @media (min-width: 768px) {
      .project-item-name {
        line-height: 2.8rem; } }
    @media (min-width: 992px) {
      .project-item-name {
        line-height: 2.625rem; } }
    @media (min-width: 1200px) {
      .project-item-name {
        line-height: 2.5rem; } }
  .project-item-perex {
    display: none; }
  .project-item:focus, .project-item:hover {
    text-decoration: none; }
    .project-item:focus .project-item-image-inner, .project-item:hover .project-item-image-inner {
      opacity: 1; }
  .project-item.active .project-item-image {
    border-color: #D81E04;
    box-shadow: 0 0 0 2px #D81E04; }

.project-content-inner {
  margin: 4rem 0 0 0;
  background: #D81E04;
  padding: 4rem;
  position: relative;
  color: #fff; }
  .project-content-inner:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    border-bottom: 26px solid #D81E04;
    position: absolute;
    top: -25px;
    left: 50%;
    margin-left: -13px; }

.top-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .top-image-left {
    width: 100%;
    color: #676D6F; }
    @media (min-width: 768px) {
      .top-image-left {
        width: 50%; } }
    .top-image-left h1 {
      margin: 0 0 1rem 0; }
    .top-image-left h2 {
      font-family: "Poppins", sans-serif;
      color: #676D6F;
      margin: 0 0 2rem 0; }
      @media (min-width: 320px) {
        .top-image-left h2 {
          font-size: 4rem; } }
    @media (min-width: 320px) {
      .top-image-left {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .top-image-left {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .top-image-left {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .top-image-left {
        font-size: 2.4rem; } }
    @media (min-width: 992px) {
      .top-image-left {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .top-image-left {
        font-size: 2rem; } }
    .top-image-left-btn {
      padding: 3rem 0 0 0; }
  .top-image-right {
    width: 100%;
    padding: 3rem 0 0 0; }
    @media (min-width: 768px) {
      .top-image-right {
        width: 50%;
        padding: 0; } }

.pux-infinite-repeater-options {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 4rem; }
  @media (min-width: 768px) {
    .pux-infinite-repeater-options {
      margin-left: -5rem;
      margin-right: -5rem; } }

#GDPRModal {
  color: #000;
  text-align: left; }
  #GDPRModal .modal-dialog {
    max-width: 800px;
    width: 90%; }
    #GDPRModal .modal-dialog .modal-content {
      position: relative;
      padding: 4rem 2rem 4rem; }
  #GDPRModal .close {
    position: absolute;
    top: 10px;
    right: 10px; }

.gallery {
  margin: 3rem 0 6rem; }
  .gallery-item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-position: center;
    background-size: cover; }
    .gallery-item:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(216, 32, 21, 0.7);
      width: 100%;
      opacity: 0;
      padding-top: 62%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      transition: border-color 200ms ease-in-out; }
      .gallery-item:before span {
        color: #fff; }
    .gallery-item:hover:before, .gallery-item:focus:before {
      opacity: 1; }
    .gallery-item:hover:after, .gallery-item:focus:after {
      opacity: 1;
      transition-delay: 100ms; }

.small-space .row {
  margin-left: -10px;
  margin-right: -10px; }
  .small-space .row [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.link-box {
  display: flex;
  flex-direction: column; }
  .link-box a:not(:first-of-type) {
    margin-top: 0.8rem; }

.InternetExplorer .header-logo img {
  height: auto !important;
  max-height: 7rem;
  max-width: 15.7rem; }

.InternetExplorer .top-image-right img {
  width: 100%; }

.votes-bg {
  position: relative; }
  @media (min-width: 1200px) {
    .votes-bg {
      min-height: 82rem; } }
  @media (min-width: 1200px) {
    .votes-bg:before {
      content: '';
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      height: 82rem;
      background-image: url("../img/ilustrace-group-4.svg");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: 22; } }
  .votes-bg .pux-container {
    position: relative;
    z-index: 44; }

.votes-row {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  @media (min-width: 768px) {
    .votes-row {
      flex-direction: row; } }

.votes-column {
  width: 100%; }
  @media (min-width: 768px) {
    .votes-column {
      width: 50%; } }
  .votes-column h3 {
    margin: 3rem 0 1.5rem 0; }

.votes-column-arrow {
  display: none; }
  @media (min-width: 768px) {
    .votes-column-arrow {
      display: block;
      flex-shrink: 0;
      position: relative;
      background-color: #AEAFAB;
      margin: 0 29px;
      width: 2px; } }

.vote-wrapper input[type='radio'] {
  display: none; }
  .vote-wrapper input[type='radio']:checked + .vote .vote-checkmark:before, .vote-wrapper input[type='radio']:checked + .vote .vote-checkmark:after {
    opacity: 1; }

@media (min-width: 320px) and (max-width: 991px) {
  .vote-wrapper.vote-open .vote .vote-body .vote-description {
    display: block;
    margin-bottom: 10px; }
  .vote-wrapper.vote-open .vote .vote-body .vote-show-moreX {
    display: none; }
  .vote-wrapper.vote-open .vote .vote-body .vote-show-lessX {
    display: block; } }

.vote-wrapper.vote-wrapper-with-image {
  display: flex;
  flex-direction: column-reverse; }
  .vote-wrapper.vote-wrapper-with-image .vote-image {
    cursor: pointer;
    height: 200px; }
    .vote-wrapper.vote-wrapper-with-image .vote-image img {
      height: 200px !important;
      width: 100%;
      object-fit: cover;
      object-position: center; }

.vote {
  position: relative;
  z-index: 23;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: #AEAFAB;
  padding: 25px;
  margin-bottom: 30px;
  cursor: pointer; }
  @media (min-width: 545px) {
    .vote {
      flex-wrap: nowrap;
      justify-content: flex-start; } }
  @media (min-width: 768px) {
    .vote {
      flex-wrap: wrap;
      justify-content: center; } }
  @media (min-width: 992px) {
    .vote {
      flex-wrap: nowrap;
      justify-content: flex-start; } }
  .vote:last-child {
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .vote:last-child {
        margin-bottom: 50px; } }
    @media (min-width: 992px) {
      .vote:last-child {
        margin-bottom: 40px; } }
  .vote .vote-checkmark {
    position: absolute;
    top: 20px;
    right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: #D81E04;
    margin-left: auto; }
    @media (min-width: 545px) {
      .vote .vote-checkmark {
        position: relative;
        top: 0;
        right: 0; } }
    @media (min-width: 768px) {
      .vote .vote-checkmark {
        position: absolute;
        top: 20px;
        right: 20px; } }
    @media (min-width: 992px) {
      .vote .vote-checkmark {
        position: relative;
        top: 0;
        right: 0; } }
    .vote .vote-checkmark:before, .vote .vote-checkmark:after {
      opacity: 0;
      transition: opacity 200ms; }
    .vote .vote-checkmark:before {
      position: absolute;
      bottom: 20px;
      left: 10px;
      content: '';
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: #fff;
      z-index: 1;
      transform: rotate(-45deg); }
    .vote .vote-checkmark:after {
      position: absolute;
      bottom: 15px;
      left: 4px;
      content: '';
      width: 15px;
      height: 4px;
      border-radius: 2px;
      background-color: #fff;
      z-index: 1;
      transform: rotate(45deg); }
  .vote .vote-body {
    height: auto;
    align-self: center;
    margin-right: 10px;
    width: 100%; }
    .vote .vote-body .vote-title {
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 10px; }
      @media (min-width: 320px) {
        .vote .vote-body .vote-title {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .vote .vote-body .vote-title {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .vote .vote-body .vote-title {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .vote .vote-body .vote-title {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .vote .vote-body .vote-title {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .vote .vote-body .vote-title {
          font-size: 2rem; } }
    .vote .vote-body .vote-description {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s cubic-bezier(0, 1, 0, 1); }
    .vote .vote-body .vote-show-moreX .btn,
    .vote .vote-body .vote-show-lessX .btn {
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      text-decoration: underline;
      padding: 0;
      color: #D81E04; }
    .vote .vote-body .vote-show-moreX {
      display: block; }
    .vote .vote-body .vote-show-lessX {
      display: none; }
  .vote .vote-icon {
    margin-right: 0;
    margin-bottom: 20px; }
    @media (min-width: 545px) {
      .vote .vote-icon {
        margin-right: 20px;
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      .vote .vote-icon {
        margin-right: 0;
        margin-bottom: 20px; } }
    @media (min-width: 992px) {
      .vote .vote-icon {
        margin-right: 20px;
        margin-bottom: 0; } }
    .vote .vote-icon:before {
      font-size: 96px;
      color: #fff; }
    .vote .vote-icon.vote-icon-culture:before {
      content: '\e90d'; }
    .vote .vote-icon.vote-icon-lake:before {
      content: '\e90f'; }
    .vote .vote-icon.vote-icon-skate:before {
      content: '\e911'; }
    .vote .vote-icon.vote-icon-wifi:before {
      content: '\e912'; }
    .vote .vote-icon.vote-icon-trees:before {
      content: '\e910'; }
    .vote .vote-icon.vote-icon-dog:before {
      content: '\e90e'; }
  .vote.open-text .vote-description {
    max-height: 1500px;
    transition: 0.75s ease-in-out; }
  .vote.open-text .vote-show-moreX {
    display: none; }
  .vote.open-text .vote-show-lessX {
    display: block; }

.vote-form-title {
  text-align: center;
  font-weight: 700; }
  @media (min-width: 320px) {
    .vote-form-title {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .vote-form-title {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .vote-form-title {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .vote-form-title {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .vote-form-title {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .vote-form-title {
      font-size: 2rem; } }

.vote-form-email span {
  margin-top: 5px;
  color: #fff;
  animation: blinkError 2s; }

.form-result-info {
  text-align: center; }

.form-result-info-error {
  margin-top: 5px;
  color: #fff; }

.form-result-info-success {
  color: #fff;
  margin: 20rem 0;
  font-weight: bold; }
  @media (min-width: 320px) {
    .form-result-info-success {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    .form-result-info-success {
      font-size: 4.54545rem; } }
  @media (min-width: 545px) {
    .form-result-info-success {
      font-size: 4.16667rem; } }
  @media (min-width: 768px) {
    .form-result-info-success {
      font-size: 3.5rem; } }

.votes-form-wrapper {
  display: flex;
  justify-content: center; }

.votes-form {
  max-width: 670px;
  width: 100%; }
  .votes-form .checkbox {
    padding: 10px 0 4.5rem 0; }
  .votes-form .votes-form-button {
    display: flex;
    justify-content: center; }

.votes-success {
  min-height: 70rem;
  color: #fff; }
  .votes-success h1 {
    color: #fff; }
  .votes-success.votes-bg {
    position: relative; }
    @media (min-width: 1200px) {
      .votes-success.votes-bg:before {
        background-position: center; } }

.slider-gallery {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100vw;
  overflow: hidden; }
  .slider-gallery .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 7rem;
    height: 7rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: none;
    font-size: 0;
    background: transparent;
    outline: 0;
    z-index: 22;
    cursor: pointer; }
    .slider-gallery .slick-arrow:before {
      font-size: 7rem;
      color: #fff;
      opacity: 0.8; }
    .slider-gallery .slick-arrow.slick-prev {
      left: 2rem; }
      .slider-gallery .slick-arrow.slick-prev:before {
        content: ""; }
    .slider-gallery .slick-arrow.slick-next {
      right: 2rem; }
      .slider-gallery .slick-arrow.slick-next:before {
        content: ""; }
  .slider-gallery-item {
    flex: 0 0 100vw;
    max-width: 100vw; }
    .slider-gallery-item img {
      width: 100%; }
    .slider-gallery-item .pux-lazy-box-item {
      background-position: center;
      background-size: cover;
      padding-top: 50%; }
  .slider-gallery.slick-initialized {
    display: block; }
  .slider-gallery-container {
    position: relative; }
  .slider-gallery-numbers {
    position: absolute;
    font-size: 6rem;
    top: 5px;
    left: 15px;
    color: #fff;
    text-shadow: 0 0 3px #000;
    font-weight: 700;
    opacity: 0.8;
    z-index: 99; }
    @media (min-width: 480px) {
      .slider-gallery-numbers {
        top: 20px;
        left: 30px; } }
    @media (min-width: 768px) {
      .slider-gallery-numbers {
        top: 3rem;
        left: 5rem; } }
  .slider-gallery-play {
    position: absolute;
    font-size: 6rem;
    bottom: 5px;
    left: 15px;
    opacity: 0.8;
    z-index: 99;
    cursor: pointer; }
    @media (min-width: 480px) {
      .slider-gallery-play {
        bottom: 20px;
        left: 30px; } }
    @media (min-width: 768px) {
      .slider-gallery-play {
        bottom: 3rem;
        left: 5rem; } }
    .slider-gallery-play:before {
      content: "\e914";
      font-size: 5rem;
      color: #fff;
      text-shadow: 0 0 3px #000;
      font-weight: 700; }
    .slider-gallery-play.playing:before {
      content: "\e913"; }

@keyframes blinkError {
  0% {
    color: #d82015; }
  20% {
    color: #fff; }
  40% {
    color: #d82015; }
  60% {
    color: #fff; }
  80% {
    color: #d82015; }
  100% {
    color: #fff; } }

.content-iframe {
  position: relative; }
  .content-iframe iframe {
    width: 100%;
    border: none; }
  .content-iframe.iframe-youtube {
    padding-bottom: 56.25%;
    height: 0; }
    .content-iframe.iframe-youtube iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  background: #fff;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  font-weight: 900;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-top: 1.5rem solid #D81E04; }
      @media (min-width: 768px) {
        .selectric .button:before {
          width: 0;
          height: 0;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 1rem solid #D81E04; } }

.selectric-focus .selectric {
  border-color: #D81E04; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #D81E04;
    border-bottom: 1px solid #D81E04; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #D81E04;
    border-top: 1px solid #D81E04; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #e6e6e6;
  border: 2px solid #fff;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #000;
    background: #f2f2f2;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #000; }
    .selectric-items li.highlighted {
      color: #000; }
    .selectric-items li:hover {
      background: #ededed; }
    .selectric-items li:nth-child(odd) {
      background: #e6e6e6; }
      .selectric-items li:nth-child(odd):hover {
        background: #e0e0e0; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #000 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #000; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: #cccccc;
    vertical-align: middle;
    position: static;
    border: 2px solid #cccccc; }
  .list-box .selectric-scroll ul li.selected:before {
    background: #D81E04; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

@media print {
  header,
  footer {
    display: none; } }
