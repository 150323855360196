@import '_mixins/pux/breakpoints.scss';
@import '_mixins/pux/css-rules.scss';
@import '_mixins/pux/pux-content.scss';
@import '_mixins/pux/scale.scss';
@import '_variables.scss';

.dark-box {
  background-color: $dark-grey;
  color: #fff;

  h2,
  h3 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 2.5rem;
  }

  .ErrorLabel {
    display: none;
  }

  .row-form {
    padding: 1rem 0 0 0;
  }

  &.contact-bgc {
    @include media(xl) {
      background-image: url('../img/ilustrace-group-3.svg');
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      min-height: 40vw;
      padding-left: 20vw;
      padding-right: 20vw;
    }
  }
}

.tree-bgc {
  position: relative;

  &:before {
    @include media(xl) {
      content: '';
      position: absolute;
      bottom: -5rem;
      left: 0;
      width: 100%;
      height: 1186px;
      background-image: url('../img/ilustrace-group-2.svg');
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      z-index: 22;
    }
  }

  .pux-container {
    position: relative;
    z-index: 44;
  }
}

.first-bgc {
  position: relative;

  &:before {
    @include media(xl) {
      content: '';
      position: absolute;
      top: 8rem;
      left: 0;
      width: 100%;
      height: 686px;
      background-image: url('../img/ilustrace-group-1.svg');
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: contain;
      z-index: 22;
    }
  }

  .pux-container {
    position: relative;
    z-index: 44;
  }
}

.light-box {
  background: $second-color;
  color: $dark-grey;
  position: relative;

  h2,
  h3 {
    margin-top: 0;
    color: $dark-grey;
  }

  .scroll-btn {
    background: $second-color;
  }
}

.white-box {
  position: relative;
  color: $dark-grey;

  h2,
  h3 {
    margin-top: 0;
    color: $dark-grey;
  }
}

.form-container {
  max-width: 650px;
  margin: 0 auto;
}

.title-box {
  display: flex;
  margin: 0 -1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 4.5rem 0;

  &-item {
    padding: 0 1rem 2rem 1rem;
  }

  h2 {
    margin: 0;
  }
}

.news-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding: 1.5rem 0 0 0;

  .news-box {
    width: 100%;
    padding: 0 1rem 2rem 1rem;

    @include media(md) {
      width: 33.33%;
    }
  }
}

.news-detail {
  color: $dark-grey;

  h1 {
    color: $dark-grey;
  }

  .perex {
    padding: 0 0 3rem 0;
  }

  &-image {
    padding: 0 0 3rem 0;
  }

  &-content {
    padding: 0 0 4rem 0;
  }
}

.news-item {
  display: block;
  color: $dark-grey;
  font-weight: 400;

  &-image {
    width: 100%;
    height: 200px;
    background-size: cover !important;
    background-position: top center !important;

    @include media(md) {
      height: 23rem;
    }
  }

  &-content {
    &-name {
      margin: 0;
      padding: 2rem 0 2rem 0;
    }

    &-link {
      margin: 2rem 0 0 0;
      color: $main-color;
      font-weight: bold;
    }
  }

  &:focus,
  &:hover {
    text-decoration: none;
    color: $dark-grey;

    .news-item-content-link {
      color: lighten($main-color, 4);
    }
  }
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -1rem;
  padding: 1rem 0 0 0;

  .project-box {
    width: 100%;
    padding: 0 1rem 2rem 1rem;

    @include media(xs) {
      width: 50%;
    }

    @include media(sm) {
      width: 33.33%;
    }

    @include media(xl) {
      width: 20%;
    }
  }
}

.project-item {
  display: block;

  &-image {
    position: relative;
    padding-top: 108%;
    width: 100%;
    border: 2px solid #fff;
    display: flex;
    background-size: cover !important;
    background-position: top center !important;
    @include transition;
    box-shadow: 0 0 0 0 $main-color;

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(216, 32, 21, 0.7);
      width: 100%;
      opacity: 0;
      transition: border-color 200ms ease-in-out;

      span {
        color: #fff;
      }
    }
  }

  &-name {
    font-weight: 600;
    color: $dark-grey;
    padding: 2rem 0 0 0;
    @include pux-scale-with-min('font-size', 20px, 18px);
    @include pux-scale-with-min('line-height', 25px, 21px);
  }

  &-perex {
    display: none;
  }

  &:focus,
  &:hover {
    text-decoration: none;

    .project-item-image-inner {
      opacity: 1;
    }
  }

  &.active {
    .project-item-image {
      border-color: $main-color;
      box-shadow: 0 0 0 2px $main-color;
    }
  }
}

.project-content {
  &-inner {
    margin: 4rem 0 0 0;
    background: $main-color;
    padding: 4rem;
    position: relative;
    color: #fff;

    &:before {
      content: '';
      @include triangle-top(26px, 26px, 26px, $main-color);
      position: absolute;
      top: -25px;
      left: 50%;
      margin-left: -13px;
    }
  }
}

.top-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &-left {
    width: 100%;
    color: $dark-grey;

    @include media(md) {
      width: 50%;
    }

    h1 {
      margin: 0 0 1rem 0;
    }

    h2 {
      font-family: $font-family;
      color: $dark-grey;
      margin: 0 0 2rem 0;
      @include pux-scale-with-min('font-size', 40px, 20px);
    }

    @include pux-scale-with-min('font-size', 20px, 18px);

    &-btn {
      padding: 3rem 0 0 0;
    }
  }

  &-right {
    width: 100%;
    padding: 3rem 0 0 0;

    @include media(md) {
      width: 50%;
      padding: 0;
    }
  }
}

.pux-infinite-repeater-options {
  margin-left: -$main-left;
  margin-right: -$main-right;
  padding-top: 4rem;

  @include media(md) {
    margin-left: -$main-left-md;
    margin-right: -$main-right-md;
  }
}

#GDPRModal {
  color: $base-black;
  text-align: left;

  .modal-dialog {
    max-width: 800px;
    width: 90%;

    .modal-content {
      position: relative;
      padding: 4rem 2rem 4rem;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.gallery {
  margin: 3rem 0 6rem;

  &-item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-position: center;
    background-size: cover;

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(216, 32, 21, 0.7);
      width: 100%;
      opacity: 0;
      padding-top: 62%;
      @include transition;
      transition: border-color 200ms ease-in-out;

      span {
        color: #fff;
      }
    }

    &:hover,
    &:focus {
      &:before {
        opacity: 1;
      }

      &:after {
        opacity: 1;
        transition-delay: 100ms;
      }
    }
  }
}

.small-space {
  @include bs-grid(10px);
}

.link-box {
  display: flex;
  flex-direction: column;

  a {
    &:not(:first-of-type) {
      margin-top: 0.8rem;
    }
  }
}

.InternetExplorer {
  .header-logo img {
    height: auto !important;
    max-height: 7rem;
    max-width: 15.7rem;
  }

  .top-image-right {
    img {
      width: 100%;
    }
  }
}

.votes-bg {
  position: relative;

  @include media(xl) {
    min-height: 82rem;
  }

  &:before {
    @include media(xl) {
      content: '';
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      height: 82rem;
      background-image: url('../img/ilustrace-group-4.svg');
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      z-index: 22;
    }
  }

  .pux-container {
    position: relative;
    z-index: 44;
  }
}

.votes-row {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media(md) {
    flex-direction: row;
  }
}

.votes-column {
  width: 100%;

  @include media(md) {
    width: 50%;
  }

  h3 {
    margin: 3rem 0 1.5rem 0;
  }
}

.votes-column-arrow {
  display: none;

  @include media(md) {
    display: block;
    flex-shrink: 0;
    position: relative;
    background-color: $vote-bg;
    margin: 0 29px;
    width: 2px;
  }
}

.vote-wrapper {
  input[type='radio'] {
    display: none;

    &:checked {
      &+.vote {
        .vote-checkmark {

          &:before,
          &:after {
            opacity: 1;
          }
        }
      }

      // & + .vote:hover {
      //   .vote-body {
      //     .vote-description {
      //       transition: none;
      //     }
      //     .vote-show-moreX {
      //       transition: none;
      //     }
      //     .vote-show-lessX {
      //       transition: none;
      //     }
      //   }
      // }
    }
  }

  &.vote-open {
    @include media(xxs, md) {
      .vote {
        .vote-body {
          .vote-description {
            display: block;
            margin-bottom: 10px;
          }

          .vote-show-moreX {
            display: none;
          }

          .vote-show-lessX {
            display: block;
          }
        }
      }
    }
  }

  &.vote-wrapper-with-image {
    display: flex;
    flex-direction: column-reverse;

    .vote-image {
      cursor: pointer;
      height: 200px;

      img {
        height: 200px !important;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.vote {
  position: relative;
  z-index: 23;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background-color: $vote-bg;
  padding: 25px;
  margin-bottom: 30px;
  cursor: pointer;

  @include media(sm) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  @include media(md) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include media(lg) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  &:last-child {
    margin-bottom: 30px;

    @include media(md) {
      margin-bottom: 50px;
    }

    @include media(lg) {
      margin-bottom: 40px;
    }
  }

  .vote-checkmark {
    position: absolute;
    top: 20px;
    right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background-color: $main-color;
    margin-left: auto;

    @include media(sm) {
      position: relative;
      top: 0;
      right: 0;
    }

    @include media(md) {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    @include media(lg) {
      position: relative;
      top: 0;
      right: 0;
    }

    &:before,
    &:after {
      opacity: 0;
      transition: opacity 200ms;
    }

    &:before {
      position: absolute;
      bottom: 20px;
      left: 10px;
      content: '';
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $base-white;
      z-index: 1;
      transform: rotate(-45deg);
    }

    &:after {
      position: absolute;
      bottom: 15px;
      left: 4px;
      content: '';
      width: 15px;
      height: 4px;
      border-radius: 2px;
      background-color: $base-white;
      z-index: 1;
      transform: rotate(45deg);
    }
  }

  .vote-body {
    height: auto;
    align-self: center;
    margin-right: 10px;
    width: 100%;

    .vote-title {
      @include pux-scale-with-min('font-size',
        $font-size-perex,
        $min-font-size-perex );
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .vote-description {
      max-height: 0;
      overflow: hidden;
      transition: 0.5s cubic-bezier(0, 1, 0, 1);
    }

    .vote-show-moreX,
    .vote-show-lessX {
      .btn {
        background-color: transparent;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        padding: 0;
        color: $main-color;
      }
    }

    .vote-show-moreX {
      display: block;
    }

    .vote-show-lessX {
      display: none;
    }
  }

  .vote-icon {
    margin-right: 0;
    margin-bottom: 20px;

    @include media(sm) {
      margin-right: 20px;
      margin-bottom: 0;
    }

    @include media(md) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    @include media(lg) {
      margin-right: 20px;
      margin-bottom: 0;
    }

    &:before {
      font-size: 96px;
      color: $base-white;
    }

    &.vote-icon-culture {
      &:before {
        content: '\e90d';
      }
    }

    &.vote-icon-lake {
      &:before {
        content: '\e90f';
      }
    }

    &.vote-icon-skate {
      &:before {
        content: '\e911';
      }
    }

    &.vote-icon-wifi {
      &:before {
        content: '\e912';
      }
    }

    &.vote-icon-trees {
      &:before {
        content: '\e910';
      }
    }

    &.vote-icon-dog {
      &:before {
        content: '\e90e';
      }
    }
  }

  &.open-text {
    .vote-description {
      max-height: 1500px;
      transition: 0.75s ease-in-out;
    }

    .vote-show-moreX {
      display: none;
    }

    .vote-show-lessX {
      display: block;
    }
  }
}

.vote-form-title {
  text-align: center;
  font-weight: 700;
  @include pux-scale-with-min('font-size',
    $font-size-perex,
    $min-font-size-perex );
}

.vote-form-email {
  span {
    margin-top: 5px;
    color: $base-white;
    animation: blinkError 2s;
  }
}

.form-result-info {
  text-align: center;
}

.form-result-info-error {
  margin-top: 5px;
  color: $base-white;
}

.form-result-info-success {
  @include pux-scale-with-min('font-size', 35px, 25px);
  color: $base-white;
  margin: 20rem 0;
  font-weight: bold;
}

.votes-form-wrapper {
  display: flex;
  justify-content: center;
}

.votes-form {
  max-width: 670px;
  width: 100%;

  .checkbox {
    padding: 10px 0 4.5rem 0;
  }

  .votes-form-button {
    display: flex;
    justify-content: center;
  }
}

.votes-success {
  min-height: 70rem;
  color: $base-white;

  h1 {
    color: $base-white;
  }

  &.votes-bg {
    position: relative;

    &:before {
      @include media(xl) {
        background-position: center;
      }
    }
  }
}

.slider-gallery {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  @include slick-arrows;

  &-item {
    flex: 0 0 100vw;
    max-width: 100vw;

    img {
      width: 100%;
    }

    .pux-lazy-box-item {
      background-position: center;
      background-size: cover;
      padding-top: 50%;
    }
  }

  &.slick-initialized {
    display: block;
  }

  &-container {
    position: relative;
  }

  &-numbers {
    position: absolute;
    font-size: 6rem;
    top: 5px;
    left: 15px;
    color: $base-white;
    text-shadow: 0 0 3px $base-black;
    font-weight: 700;
    opacity: 0.8;
    z-index: 99;

    @include media(xs) {
      top: 20px;
      left: 30px;
    }

    @include media(md) {
      top: 3rem;
      left: 5rem;
    }
  }

  &-play {
    position: absolute;
    font-size: 6rem;
    bottom: 5px;
    left: 15px;
    opacity: 0.8;
    z-index: 99;
    cursor: pointer;

    @include media(xs) {
      bottom: 20px;
      left: 30px;
    }

    @include media(md) {
      bottom: 3rem;
      left: 5rem;
    }

    &:before {
      content: "\e914";
      font-size: 5rem;
      color: $base-white;
      text-shadow: 0 0 3px $base-black;
      font-weight: 700;
    }

    &.playing {
      &:before {
        content: "\e913";
      }
    }
  }
}

@keyframes blinkError {
  0% {
    color: #d82015;
  }

  20% {
    color: $base-white;
  }

  40% {
    color: #d82015;
  }

  60% {
    color: $base-white;
  }

  80% {
    color: #d82015;
  }

  100% {
    color: $base-white;
  }
}

.content-iframe {
  position: relative;

  iframe {
    width: 100%;
    border: none;
  }

  &.iframe-youtube {
    padding-bottom: 56.25%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}