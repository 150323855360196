$px-values: (
  5px,
  10px,
  15px,
  20px,
  25px,
  30px,
  35px,
  40px,
  45px,
  50px,
  55px,
  60px,
  65px,
  70px,
  75px,
  80px,
  85px,
  90px,
  95px,
  100px
);

// bootstrap
$main-left: 15px;
$main-right: 15px;
$main-left-md: 5rem;
$main-right-md: 5rem;

// header container

$container-max-width: 133rem;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;


// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 12rem;
$fluid-container-padding-right-xl: 12rem;

// row
$row-margin-left: (
  -$main-left
);
$row-margin-right: (
  -$main-right
);
$row-margin-left-md: (
  -$main-left-md
);
$row-margin-right-md: (
  -$main-right-md
);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'Poppins', sans-serif;
$font-family-title: 'Poppins', sans-serif;
$font-size: 18px;

// perex
$font-size-perex: 20px;
$min-font-size-perex: 18px;
$line-height-perex: 26px;
$min-line-height-perex: 22px;

// small text
$font-size-small: 16px;
$min-font-size-small: 15px;
$line-height-small: 18px;
$min-line-height-small: 18px;

// main colors
$color-link: #D81E04;
$main-color: #D81E04;
$second-color: #AEAFAB;
$color-line: #f1f1f1;
$base-black: #000;
$base-white: #fff;
$base-grey: #f3f3f3;
$dark-grey: #676D6F;
$vote-bg: $second-color;

// button primary
$btn-primary-background: #d82015;
$btn-primary-color: $base-white;

// button default
$btn-default-background: $second-color;
$btn-default-color: $base-black;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: $base-grey;
$table-tr-border: #f1f1f1;
$respo-table-break: 'md';

// menu
$hamburger-width: 6rem;
$menu-break: 'sm';

// form
$color-placeholder: #000;

// inputs
$border-color: #abdbda;
$input-color: $base-black;
$input-background: #fff;
$focus-border-color: $second-color;
$input-height: 50px;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $input-color;
$select-border: $input-background;
$select-border-focus: $main-color;
$select-arrow-color: $main-color;
$select-arrow-code: '';
$select-use-triangle: 1;
$select-height: $input-height;

// datepicker
$datepicker-header-background: lighten($main-color, 5);
$datepicker-header-color: $base-white;
$datepicker-header-arrows-color: $base-white;
$datepicker-option-backgorund: $main-color;
$datepicker-option-color: $base-white;
$datepicker-selected-background: $main-color;
$datepicker-selected-color: $base-white;
$datepicker-full-background: $base-white;
$datepicker-th-color: #757575;
$datepicker-th-border: #e9ecef;
$datepicker-year-picker-color: $main-color;

// pokud se používá timepicker je potřeba definovat barvy i v /plugins/datetimepicker/bootstrap-material-datetimepicker.js

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #fff;
$radio-border: #eeeff4;
$radio-active-border: $second-color;
$radio-after-background: #d82015;

// form checkbox
$checkbox-background: #fff;
$checkbox-border: #eeeff4;
$checkbox-active-border: $second-color;
$checkbox-after-background: #d82015;

// form error
$error-input-border: #d40511;
$error-label-color: #fff;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

//scale ratios
$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg: 0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;

//*** content ***

$slider-arrow-width: 7rem;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: transparent;
$slider-arrow-radius: 50%;
$slider-arrow-border: none;
$slider-arrow-left: 2rem;
$slider-arrow-right: 2rem;
$slider-arrow-before-size: 7rem;
$slider-arrow-prev-code: '\e916';
$slider-arrow-next-code: '\e915';

$slider-dot-width: 30px;
$slider-dot-height: $slider-dot-width;
$slider-dot-background: rgba(30, 30, 30, 0.2);
$slider-dot-active: rgba(30, 30, 30, 1);
$slider-dot-border: none;
$slider-dot-radius: 50%;
$slider-dot-padding: 0 2rem 0 0;
$slider-dot-bottom: 8rem;