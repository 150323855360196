@import '_mixins/pux/breakpoints.scss';
@import '_mixins/pux/css-rules.scss';
@import '_mixins/pux/pux-content.scss';
@import '_mixins/pux/scale.scss';
@import '_variables.scss';

.cms-bootstrap [class^='icon-']:before,
.cms-bootstrap [class*=' icon-']:before {
  font-family: 'Core-icons' !important;
}

.juicer-feed {
  *:before {
    font-family: 'fontawesome-webfont' !important;
  }
}

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.juicer-feed {

  *:before,
  *:after {
    font-family: 'fontawesome-5-juicer', Helvetica, Arial, sans-serif !important;
  }

  li {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }

  button.slick-next.slick-arrow,
  button.slick-prev.slick-arrow {
    &:before {
      color: $base-white;
    }

    &:hover {
      &:before {
        color: $second-color;
      }
    }
  }
}

.j-post-overlay.juicer-feed .j-overlay-text .juicer-button {
  background-color: $base-white;
  border-color: $color-link;

  &:hover {
    background-color: $color-link;
    color: $base-white !important;
  }
}

.helper-4k {
  max-width: 200rem;
  margin: auto;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

html {
  font-size: $scale-base-size * $scale-ratio-xxs;

  @include media(xs) {
    font-size: $scale-base-size * $scale-ratio-xs;
  }

  @include media(sm) {
    font-size: $scale-base-size * $scale-ratio-sm;
  }

  @include media(md) {
    font-size: $scale-base-size * $scale-ratio-md;
  }

  @include media(lg) {
    font-size: $scale-base-size * $scale-ratio-lg;
  }

  @include media(xl) {
    font-size: $scale-base-size * $scale-ratio-xl;
  }

  @include media(xxl) {
    font-size: $scale-base-size * $scale-ratio-xxl;
  }
}

@media only screen and(-webkit-min-device-pixel-ratio: 1.25),
only screen and(-o-min-device-pixel-ratio: 5/4),
only screen and(min-resolution: 120dpi),
only screen and(min-resolution: 1.25dppx) {
  html {
    font-size: $scale-base-size * $scale-ratio-xxs;

    @include media(xs) {
      font-size: $scale-base-size * $scale-ratio-xs;
    }

    @include media(sm) {
      font-size: $scale-base-size * $scale-ratio-sm;
    }

    @include media(md) {
      font-size: $scale-base-size * $scale-ratio-md;
    }

    @include media(lg) {
      font-size: $scale-base-size * $scale-ratio-lg;
    }

    @include media(xl) {
      font-size: $scale-base-size * $scale-ratio-xl * 0.8;
    }

    @include media(xxl) {
      font-size: $scale-base-size * $scale-ratio-xxl * 0.8;
    }
  }
}

form,
html,
body {
  font-family: $font-family;
  height: 100%;
  color: $base-black;
}

body {
  @include pux-scale-with-min('font-size', 18px, 16px);
}

img:not(.j-content-image) {
  max-width: 100%;
  height: auto !important;
}

@each $value in $px-values {
  .pt-#{strip-unit($value)} {
    @include pux-scale-with-min('padding-top', $value, 20px);
  }

  .pb-#{strip-unit($value)} {
    @include pux-scale-with-min('padding-bottom', $value, 20px);
  }

  .mt-#{strip-unit($value)} {
    @include pux-scale-with-min('margin-top', $value, 20px);
  }

  .mb-#{strip-unit($value)} {
    @include pux-scale-with-min('margin-bottom', $value, 20px);
  }
}

h1,
.h1 {
  @include pux-scale-with-min('font-size', 50px, 30px);
  color: $main-color;
  margin: 4rem 0 3rem 0;
  font-weight: 700;
  font-family: $font-family-title;
}

h2,
.h2 {
  @include pux-scale-with-min('font-size', 35px, 25px);
  color: $main-color;
  margin: 4rem 0;
  font-weight: 700;
  font-family: $font-family-title;
}

h3,
.h3 {
  @include pux-scale-with-min('font-size', 24px, 20px);
  color: $main-color;
  font-weight: 700;
  font-family: $font-family-title;
}

h4,
.h4 {
  @include pux-scale-with-min('font-size', 18px, 18px);
  color: $main-color;
  font-weight: bold;
}

big,
.perex {
  @include pux-scale-with-min('font-size',
    $font-size-perex,
    $min-font-size-perex );
}

small,
.text-small {
  @include pux-scale-with-min('font-size',
    $font-size-small,
    $min-font-size-small );
}

a {
  color: $color-link;
  cursor: pointer;

  &:focus,
  &:hover {
    color: lighten($color-link, 5);
    outline: none;
    text-decoration: underline;
  }
}

.link {
  text-decoration: none;

  &:before {
    font-size: 12px;
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.link-back {
  display: inline-flex;
  align-items: center;

  &:before {
    content: '\e906';
    margin: 0 1.5rem 0 0;
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;

  &:before {
    content: '\e903';
    margin: 0 1.5rem 0 0;
  }
}

.scroll-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  @include rotate(45deg);
  background: #fff;
  position: absolute;
  bottom: -5rem;
  left: 50%;
  z-index: 44;
  @include transform(rotate(45deg) translatex(-50%));

  &:before {
    transition: 0.3s ease color;
    content: '\e900';
    @include rotate(-45deg);
    @include pux-scale-with-min('font-size', 30px, 20px);
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn {
  display: inline-block;
  @include border-radius(0);
  border: none;
  padding: 2.25rem 5rem;
  @include box-shadow(none);
  @include pux-scale-with-min('font-size', 18px, 16px);
  font-weight: bold;

  &:focus,
  &:hover {
    text-decoration: none;
    @include box-shadow(none);
  }
}

.btn-primary {
  background: $btn-primary-background;
  color: $btn-primary-color;

  &:focus,
  &:hover {
    text-decoration: none;
    background: darken($btn-primary-background, 5);
    color: $btn-primary-color;
  }
}

.btn-default {
  background: $main-color;
  color: $base-white;

  &:focus,
  &:hover {
    text-decoration: none;
    background: lighten($main-color, 4);

  }
}

.image-top-center {
  position: top center !important;
}

.image-top-left {
  position: top left !important;
}

.image-top-right {
  position: top left !important;
}

.image-bottom-center {
  position: bottom center !important;
}

.image-bottom-left {
  position: bottom left !important;
}

.image-bottom-right {
  position: bottom right !important;
}

main {
  min-height: 40rem;
  padding: 7rem 0 0 0;

  a {
    font-weight: bold;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol;

    li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 2.5rem;

      &:before {
        content: counter(my-ol);
        counter-increment: my-ol;
        color: $ol-number;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: $font-family !important;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 4rem;
      position: relative;

      &:before {
        content: '';
        width: 2rem;
        height: 1px;
        background: $ul-bullet;
        position: absolute;
        top: 11px;
        left: 0;
        @include border-radius(50%);
      }
    }
  }

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid $table-tr-border;

      td {
        padding: 1rem 1.5rem;
      }

      &:nth-child(even) {
        background: $table-even-background;
      }
    }
  }

  .respo-table {
    table {
      width: 100%;

      thead {
        display: none;

        @include media($respo-table-break) {
          display: table-header-group;
        }

        tr {
          border-bottom: 1px solid $table-tr-border;

          th {
            font-weight: bold;
            padding: 1rem 1.5rem;
          }
        }
      }

      tbody {
        tr {
          display: block;
          border: 1px solid $table-tr-border;
          border-bottom: none;
          margin: 0 0 2rem 0;

          &:nth-child(even) {
            background: $table-even-background;
          }

          @include media($respo-table-break) {
            display: table-row;
            margin: 0;
            border: none;
            border-bottom: 1px solid $table-tr-border;
          }

          td {
            @include flex-block;
            @include justify-content(space-between);
            width: 100%;
            border-bottom: 1px solid $table-tr-border;

            @include media($respo-table-break) {
              display: table-cell;
              border: none;
              width: auto;
            }

            &:before {
              content: attr(data-th);
              display: block;
              text-align: left;
              font-family: $font-family !important;
              margin: 0 1.5rem 0 0;

              @include media($respo-table-break) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}