@import '_mixins/pux/breakpoints.scss';
@import '_mixins/pux/css-rules.scss';
@import '_mixins/pux/pux-content.scss';
@import '_mixins/pux/scale.scss';
@import '_variables.scss';

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  max-width: $container-max-width;

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  @include media(md) {
    padding-left: $container-padding-left-md;
    padding-right: $container-padding-right-md;
  }
}

.pux-container {
  .row {
    @include flex-block;
    @include flex-wrap(wrap);
    margin-left: $row-margin-left;
    margin-right: $row-margin-right;

    @include media(md) {
      margin-left: $row-margin-left-md;
      margin-right: $row-margin-right-md;
    }
  }
}

.pux-container {
  [class^='col'] {
    padding-left: $col-padding-left;
    padding-right: $col-padding-right;

    @include media(md) {
      padding-left: $col-padding-left-md;
      padding-right: $col-padding-right-md;
    }
  }
}

.pux-fluid-container {
  padding-left: $fluid-container-padding-left;
  padding-right: $fluid-container-padding-right;

  @include media(md) {
    padding-left: $fluid-container-padding-left-md;
    padding-right: $fluid-container-padding-right-md;
  }

  @include media(xl) {
    padding-left: $fluid-container-padding-left-xl;
    padding-right: $fluid-container-padding-right-xl;
  }
}

.small-container {
  max-width: 900px;
  margin: 0 auto;
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: $base-grey;
  @include transition;
  z-index: 999;

  @include media($menu-break) {
    background: #fff;
  }

  &.down {
    top: -14rem;

    @include media($menu-break) {
      top: -7rem;
    }
  }

  &.up {
    top: 0;
    @include box-shadow(0 8px 8px rgba(119, 124, 127, 0.3));
  }

  .pux-container {
    @include media(xxs, xs) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .header {
    height: 7rem;
    background: $dark-grey;
    @include flex-block;
    @include justify-content(space-between);

    &-logo {
      margin: 0 5rem 0 5rem;
      background: $base-white;
      display: flex;
      align-items: center;

      @include media($menu-break) {
        margin: 0 5rem 0 0;
      }

      a {
        display: inline-block;

        span {
          display: none;
        }

        img {
          height: 7rem !important;
        }
      }
    }

    &-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      background: $base-grey;
      width: 100%;
      height: 7rem;
      justify-content: center;
      @include calc('max-height', '100vh - 5rem');
      @include box-shadow(0 8px 8px rgba(119, 124, 127, 0.3));

      @include media($menu-break) {
        @include flex-block;
        position: static;
        background: none;
        width: auto;
        height: auto;
        @include box-shadow(none);
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media($menu-break) {
          @include flex-block;
        }

        li {
          display: block;
          position: relative;

          @include media($menu-break) {
            margin: 0 1rem;
            @include flex-block;
            @include align-items(center);
          }

          a {
            display: block;
            padding: 1.5rem 1.5rem;
            color: $base-black;

            &:focus,
            &:hover {
              color: $main-color;
              text-decoration: none;
            }
          }

          &.Highlighted {
            a {
              color: $main-color;
            }
          }

          ul {
            background: $second-color;
            display: none;
            width: 100%;
            @include opacity(1);
            visibility: visible;
            @include transition;

            @include media($menu-break) {
              position: absolute;
              width: 20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
              top: 100%;
            }

            li {
              a {

                &:focus,
                &:hover {}
              }
            }
          }

          &.hovered,
          &:hover {
            &>a {
              color: $main-color;
            }

            &>ul {
              visibility: visible;
              @include opacity(1);
            }
          }

          &.open {
            .menu-openner {
              &:after {
                @include rotate(0deg);
              }
            }

            ul {
              display: block;
            }
          }
        }
      }

      .menu-openner {
        position: absolute;
        top: 0;
        right: 0;
        background: $main-color;
        color: #fff;
        width: 6rem;
        height: 6rem;

        @include media($menu-break) {
          display: none;
        }

        &:after,
        &:before {
          content: '';
          width: 70%;
          height: 2px;
          background: #fff;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px;
        }

        &:after {
          @include rotate(90deg);
          @include transition;
        }
      }
    }

    &-hamburger {
      padding: 0 15px 0 0;
      display: flex;
      align-items: center;

      @include media($menu-break) {
        display: none;
      }

      .open-menu {
        width: $hamburger-width;
        height: $hamburger-width;
        position: relative;
        display: block;

        span {
          width: $hamburger-width - 1rem;
          position: absolute;
          left: 0.5rem;
          height: 2px;
          background: $main-color;
          margin-top: -1px;
          top: 50%;
          @include transition;

          &:before,
          &:after {
            content: '';
            width: $hamburger-width - 1rem;
            left: 0;
            height: 2px;
            background: $main-color;
            position: absolute;
            @include transition;
          }

          &:before {
            top: -1rem;
          }

          &:after {
            bottom: -1rem;
          }
        }
      }
    }

    &.open {
      .header-hamburger {
        .open-menu {
          span {
            background: transparent;

            &:before {
              top: 0;
              @include rotate(45deg);
            }

            &:after {
              top: 0;
              @include rotate(-45deg);
            }
          }
        }
      }

      .header-menu {
        display: flex;
      }
    }
  }

  .dark-box {
    display: none;

    @include media($menu-break) {
      width: 8rem;
      background: #777c7f;
      display: block;
    }
  }

  .second-box,
  .main-box {
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 1.5rem;
    min-width: 165px;
    flex-grow: 1;
    @include pux-scale-with-min('font-size', 16px, 15px);

    @include media($menu-break) {
      flex-grow: 0;
    }
  }

  .second-box {
    background: $main-color;
    border-right: 0.5px rgba($base-white, 0.5) solid;

    &:focus,
    &:hover {
      color: #fff;
      text-decoration: none;
      background: darken($main-color, 5);
    }
  }

  .main-box {
    background: $main-color;

    &:focus,
    &:hover {
      color: #fff;
      text-decoration: none;
      background: darken($main-color, 5);
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer {
  padding: 3rem 0 3rem 0;
  color: #fff;
  background: #777c7f;

  .footer-logo {
    img {
      max-height: 65px !important;
    }
  }

  .footer-gdpr {
    margin: 0 0 0 4rem;
  }

  .footer {
    text-align: center;

    @include media(sm) {
      text-align: left;
    }
  }

  .footer-social {
    max-width: 150px;
    display: inline-block;
    min-width: 150px;

    ul {
      margin: 0 -1rem;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        margin: 0;
        padding: 0 1rem;

        a {
          font-weight: 400;
          text-decoration: none;

          &.facebook {
            &:before {
              content: '\e907';
              font-size: 30px;
            }
          }

          &.instagram {
            &:before {
              content: '\e908';
              font-size: 27px;
            }
          }

          &.linkedin {
            &:before {
              content: '\e909';
              font-size: 27px;
            }
          }

          &.youtube {
            &:before {
              content: '\e90c';
              font-size: 35px;
            }
          }

          &.twitter {
            &:before {
              content: '\e917';
              font-size: 27px;
            }
          }

          &.threads {
            &:before {
              content: '\e918';
              font-size: 27px;
            }
          }

          &:focus,
          &:hover {
            color: $second-color;
          }
        }
      }
    }
  }

  h4 {
    color: #fff;
    margin: 3rem 0 3rem 0;
  }

  a {
    color: #fff;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  .footer {
    border-bottom: 2px solid #fff;
    padding: 0 0 4rem 0;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 2rem 0 0 0;

    &-item {
      padding: 1rem;
    }
  }
}

.is-xxl {
  display: none;

  @include media(xxl) {
    display: block;
  }
}