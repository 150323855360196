@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9wrdng');
  src:  url('fonts/icomoon.eot?9wrdng#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?9wrdng') format('woff2'),
    url('fonts/icomoon.ttf?9wrdng') format('truetype'),
    url('fonts/icomoon.woff?9wrdng') format('woff'),
    url('fonts/icomoon.svg?9wrdng#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-threads-logo:before {
  content: "\e918";
}
.icon-X_logo_2023_original:before {
  content: "\e917";
}
.icon-citadela-outline:before {
  content: "\e90d";
  color: #009c95;
}
.icon-dog-outline:before {
  content: "\e90e";
  color: #009c95;
}
.icon-lake-outline:before {
  content: "\e90f";
  color: #009c95;
}
.icon-park-outline:before {
  content: "\e910";
  color: #009c95;
}
.icon-skate-outline:before {
  content: "\e911";
  color: #009c95;
}
.icon-wifi-outline:before {
  content: "\e912";
  color: #009c95;
}
.icon-scroll:before {
  content: "\e900";
  color: #00918e;
}
.icon-ilustrace-form-left:before {
  content: "\e901";
  color: #fff;
}
.icon-ilustrace-form-right:before {
  content: "\e902";
  color: #fff;
}
.icon-arrow-right:before {
  content: "\e903";
  color: #00918e;
}
.icon-arrow-down:before {
  content: "\e904";
  color: #00918e;
}
.icon-plus:before {
  content: "\e905";
  color: #fff;
}
.icon-arrow-left:before {
  content: "\e906";
  color: #00918e;
}
.icon-facebook-f-brands:before {
  content: "\e907";
  color: #fff;
}
.icon-instagram-brands:before {
  content: "\e908";
  color: #fff;
}
.icon-linkedin-in-brands:before {
  content: "\e909";
  color: #fff;
}
.icon-logo-footer:before {
  content: "\e90a";
  color: #fff;
}
.icon-chevron-right:before {
  content: "\e915";
}
.icon-chevron-left:before {
  content: "\e916";
}
.icon-pause:before {
  content: "\e913";
}
.icon-player:before {
  content: "\e913";
}
.icon-play:before {
  content: "\e914";
}
.icon-player1:before {
  content: "\e914";
}
.icon-twitter:before {
  content: "\e90b";
}
.icon-brand:before {
  content: "\e90b";
}
.icon-tweet:before {
  content: "\e90b";
}
.icon-social:before {
  content: "\e90b";
}
.icon-youtube:before {
  content: "\e90c";
}
.icon-brand1:before {
  content: "\e90c";
}
.icon-social1:before {
  content: "\e90c";
}
